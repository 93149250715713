import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const Dashboard = () => {
  const [analytics, setAnalytics] = useState({
    totalOrders: 0,
    totalRevenue: 0,
    completedOrders: 0,
    canceledOrders: 0,
    preparingOrders: 0,
    readyForPickupOrders: 0,
    orderReceived: 0,
    branchAnalytics: {
      main: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },
      second: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },
      third: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },
      fourth: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },

    },
  });

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [currentDate, setCurrentDate] = useState('');
  const [branchUpdates, setBranchUpdates] = useState({
    main: [],
    second: [],
    third: [],
    fourth: [],
  });
  
  const fetchAnalytics = async () => {
    try {
      const token = Cookies.get('token');
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_SERVER}/api/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const orders = response.data;
  
      // Get today's date
      const today = new Date();
      const year = today.getFullYear().toString().slice(-2);
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
  
      setCurrentDate(today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }));
  
      const filteredOrders = orders.filter(order => {
        const orderNumber = order.orderNumber;
        const strippedOrderNumber = orderNumber.slice(4);
        const orderYear = strippedOrderNumber.slice(0, 2);
        const orderMonth = strippedOrderNumber.slice(2, 4);
        const orderDay = strippedOrderNumber.slice(4, 6);
        return orderYear === year && orderMonth === month && orderDay === day;
      });
  
      const branchAnalytics = {
        main: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },
        second: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },
        third: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },
        fourth: { totalOrders: 0, totalRevenue: 0, completedOrders: 0, canceledOrders: 0, preparingOrders: 0, readyForPickupOrders: 0, orderReceived: 0 },

      };
  
      let totalCompletedOrders = 0;
      let totalCanceledOrders = 0;
      let totalPreparingOrders = 0;
      let totalReadyForPickupOrders = 0;
      let totalOrderReceived = 0;
  
      filteredOrders.forEach(order => {
        const branch = order.branch;
  
        if (branchAnalytics[branch]) {
          branchAnalytics[branch].totalOrders += 1;
  
          if (order.status === 'Picked Up') {
            branchAnalytics[branch].totalRevenue += order.total;
            branchAnalytics[branch].completedOrders += 1;
            totalCompletedOrders += 1;
          } else if (order.status === 'Canceled') {
            branchAnalytics[branch].canceledOrders += 1;
            totalCanceledOrders += 1;
          } else if (order.status === 'Preparing') {
            branchAnalytics[branch].preparingOrders += 1;
            totalPreparingOrders += 1;
          } else if (order.status === 'Ready for Pickup') {
            branchAnalytics[branch].readyForPickupOrders += 1;
            totalReadyForPickupOrders += 1;
          } else if (order.status === 'Order Received') {
            branchAnalytics[branch].orderReceived += 1;
            totalOrderReceived += 1;
          }
        }
      });
  
      const totalRevenue = filteredOrders.reduce((sum, order) => sum + (order.status === 'Picked Up' ? order.total : 0), 0);
      const totalOrders = filteredOrders.length;
  
      setAnalytics(prev => ({
        ...prev,
        totalOrders,
        totalRevenue,
        completedOrders: totalCompletedOrders,
        canceledOrders: totalCanceledOrders,
        preparingOrders: totalPreparingOrders,
        readyForPickupOrders: totalReadyForPickupOrders,
        orderReceived: totalOrderReceived,
        branchAnalytics,
      }));
  
      setLastUpdated(new Date());
  
    } catch (error) {
      console.error('Error fetching orders for analytics', error);
    }
  };
  
  const fetchBranchLogs = async (branch) => {
    try {
      const token = Cookies.get('token');
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_SERVER}/api/logs/${branch}/last-5`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBranchUpdates((prev) => ({
        ...prev,
        [branch]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching logs for ${branch} branch`, error);
    }
  };
  

  useEffect(() => {
    fetchAnalytics(); 
    const intervalId = setInterval(fetchAnalytics, 900000); 

    return () => clearInterval(intervalId); 
  }, []);

  useEffect(() => {
    const branches = ['main', 'second', 'third', 'fourth'];
    branches.forEach((branch) => fetchBranchLogs(branch));
  
    const intervalId = setInterval(() => {
      branches.forEach((branch) => fetchBranchLogs(branch));
    }, 900000); // Update every 15 minutes
  
    return () => clearInterval(intervalId);
  }, []);
  

  return (
    <div className="min-h-screen bg-gray-100 p-10">
      <h1 className="text-3xl font-bold mb-6">Order Analytics Dashboard</h1>
      <p className="text-gray-600 mb-4">Last updated: {lastUpdated.toLocaleString('en-US', {
  weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
})}</p>
<p className="text-gray-600 mb-4">Current Date: {new Date().toLocaleString('en-US', {
  weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
})}</p>

      <div className="mt-10">
  <h2 className="text-2xl font-bold mb-4">Latest Updates - All Branches</h2>
  <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
  {['main', 'second', 'third', 'fourth'].map((branch) => (
    <div key={branch} className="bg-white shadow-lg rounded-lg p-6 relative">
      {/* Availability Change Badge */}
      <div className={`absolute top-4 right-4 px-4 py-2 text-sm font-semibold rounded-full text-white ${branchUpdates[branch].some(log => log.details.availabilityChange === '[ADDED]') ? 'bg-green-500' : 'bg-red-500'}`}>
        {branchUpdates[branch].some(log => log.details.availabilityChange === '[ADDED]')
          ? 'ADDED'
          : 'REMOVED'}
      </div>

      {/* Branch Name */}
      <h3 className="text-2xl font-semibold text-gray-800 capitalize mb-4">
        {branch} Branch
      </h3>

      <ul>
        {branchUpdates[branch].map((log) => (
          <li key={log._id} className="mb-6 pb-6 border-b border-gray-200 last:border-0">
            {/* Log Content */}
            <div className="space-y-3">
         
              <div className="flex items-center space-x-4">
                <p className="text-lg font-medium text-gray-800">
                  <strong>Product:</strong> {log.details.productName}
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-lg font-medium text-gray-800">
                  <strong>Variant:</strong> {log.details.variantName}
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-lg text-gray-600">
                  <strong>Timestamp:</strong>
                  <span className="ml-2 font-mono text-sm text-gray-500">
                    {new Date(log.timestamp).toLocaleString('en-US', {
                      weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
                    })}
                  </span>
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-lg font-medium text-gray-800">
                  <strong>Username:</strong> {log.username}
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <p className="text-lg font-medium text-gray-800">
                  <strong>Role:</strong> {log.role}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ))}
</div>

</div>



      {/* Total Analytics for All Branches */}
 {/* Total Analytics for All Branches */}
<div className="mt-10">
  <h2 className="text-2xl font-bold mb-4">Total Analytics Today - All Branches</h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold text-gray-700">Total Orders</h3>
      <p className="text-4xl font-bold text-indigo-600">{analytics.totalOrders}</p>
    </div>
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold text-gray-700">Total Revenue</h3>
      <p className="text-4xl font-bold text-indigo-600">₱{analytics.totalRevenue.toFixed(2)}</p>
    </div>
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold text-gray-700">Completed Orders</h3>
      <p className="text-4xl font-bold text-green-600">{analytics.completedOrders}</p>
    </div>
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold text-gray-700">Canceled Orders</h3>
      <p className="text-4xl font-bold text-red-600">{analytics.canceledOrders}</p>
    </div>
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold text-gray-700">Preparing Orders</h3>
      <p className="text-4xl font-bold text-yellow-600">{analytics.preparingOrders}</p>
    </div>
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold text-gray-700">Ready for Pickup Orders</h3>
      <p className="text-4xl font-bold text-blue-600">{analytics.readyForPickupOrders}</p>
    </div>
    <div className="bg-white shadow-md rounded-lg p-6">
      <h3 className="text-xl font-semibold text-gray-700">Order Received</h3>
      <p className="text-4xl font-bold text-purple-600">{analytics.orderReceived}</p>
    </div>
  </div>
</div>

<div className="mt-10">
        <h2 className="text-2xl font-bold mb-4">Visualization</h2>
        <iframe
          style={{ background: '#FFFFFF', border: 'none', borderRadius: '2px', boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)' }}
          width="100%"
          height="1000"
          src="https://charts.mongodb.com/charts-project-0-vlnznuk/public/dashboards/34461ebc-335d-4d84-ad49-c6729ceb1078"
          title="MongoDB Dashboard"
        ></iframe>

<iframe
      style={{
        background: '#F1F5F4',
        border: 'none',
        borderRadius: '2px',
        boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',
        width: '100%',
        height: '100vh',
      }}
      src="https://charts.mongodb.com/charts-escuebar-billiard-managem-yvqrnvq/public/dashboards/86b6ba6b-0979-40f1-9a4f-2b75bba9a2a7"
    ></iframe>

      </div>

      <hr class="border-t-2 border-gray-300 my-4"></hr>

      {Object.entries(analytics.branchAnalytics).map(([branch, data]) => (
        <div key={branch} className="mt-10">
          <h2 className="text-2xl font-bold mb-4">{branch.charAt(0).toUpperCase() + branch.slice(1)} Branch Analytics Today</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700">Total Orders</h3>
              <p className="text-4xl font-bold text-indigo-600">{data.totalOrders}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700">Total Revenue</h3>
              <p className="text-4xl font-bold text-indigo-600">₱{data.totalRevenue.toFixed(2)}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700">Completed Orders</h3>
              <p className="text-4xl font-bold text-green-600">{data.completedOrders}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700">Canceled Orders</h3>
              <p className="text-4xl font-bold text-red-600">{data.canceledOrders}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700">Preparing Orders</h3>
              <p className="text-4xl font-bold text-yellow-600">{data.preparingOrders}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700">Ready for Pickup Orders</h3>
              <p className="text-4xl font-bold text-blue-600">{data.readyForPickupOrders}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700">Order Received</h3>
              <p className="text-4xl font-bold text-purple-600">{data.orderReceived}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
